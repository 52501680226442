//
// _pagination.scss
//

// Pagination rounded (Custom)
.pagination-rounded {
    .page-link {
        border-radius: 30px !important;
        margin: 0 3px !important;
        border: none;
        width: 32px;
        height: 32px;
        padding: 0;
        text-align: center;
        line-height: 32px;
    }
    max-width: fit-content;
  }

.pagination-container {
    min-width: 64px;
    .items-per-page-dropdown {
        background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"><path d="M6 9L0 0h12L6 9z" fill="%23aaa"/></svg>') no-repeat;
        background-position: right 10px center;
        max-width: 64px;

    }
    .items-per-page-label {
        font-style: normal;
        height: 32px;
        line-height: 32px;
        color: #74788d;
        font-weight: normal;
    }
}
