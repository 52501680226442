/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5)
  }

  100% {
    transform: scale(1)
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

ngx-dropzone{
  border:2px dashed var(--bs-border-color) !important;
}

ngx-dropzone-preview {
  max-width: none !important;
  justify-content: normal !important;
  height: 80px !important;
  min-height: 80px !important;
  background-image: none !important;
  img {
    opacity: 1 !important;
  }

  ngx-dropzone-label {
    position: unset !important;
  }
}

.btn-magical {
  color: #ffffff !important;
  background-color: #876be8 !important;  /* Lighter purple */
  border-color: #745edc !important;      /* Lighter border */
  border-radius: 4px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;  /* Lighter shadow */
  transition: all 0.2s ease-in-out !important;
}

.btn-magical:hover {
  background-color: #7b60e0 !important;  /* Lighter purple when hovered */
  border-color: #684cc8 !important;      /* Lighter border when hovered */
  color: #ffe100 !important;             /* Yellow text only on hover */
}

.btn-magical:active,
.btn-magical:focus,
.btn-magical.active {
  background-color: #7b60e0 !important;  /* Lighter purple when active/focused */
  border-color: #684cc8 !important;      /* Lighter border when active/focused */
}

.btn-history {
  color: #2E2E2E !important;                /* Deep Ink for text */
  background-color: #FAEBD7 !important;     /* Aged Paper for base background */
  border-color: #CD853F !important;         /* Antique Bronze border */
  border-radius: 4px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  transition: all 0.2s ease-in-out !important;
}

.btn-history:hover {
  background-color: #CD853F !important;     /* Antique Bronze on hover */
  border-color: #A67C52 !important;         /* A bit darker shade for hover border */
  color: #FEFEFE !important;                /* White text only on hover */
}

.btn-history:active,
.btn-history:focus,
.btn-history.active {
  background-color: #A67C52 !important;     /* A bit darker shade when active/focused */
  border-color: #8B5A2B !important;         /* Even darker border when active/focused */
}









